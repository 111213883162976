import React, { Component } from 'react'

export class Home extends Component {
  render() {
    return (
      <div>

<section id="intro" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="col-md-12 col-sm-12">
				<img src="images/sheinovic.png" class="shn-logo"/>
				<p class="wow bounceIn" data-wow-delay="0.9s">Established in 2018, SHEINOVIC Holding provides support and oversees the activities of four subsidiary companies within the Myanmar and cooperate with SHEIN Planning and Consulting LLC based in Japan. SHEINOVIC Holding provides a pool of shared resources to its subsidiaries, allowing them to take full advantage of the efficiencies available through the centralization of administrative, accounting, and operational functions. With highly specialised subsidiaries, SHEINOVIC Holding offers its clients several services, ranging from consulting services through human resource development, organizational empowerment, business tour and events, business development and cooperation with local Myanmar companies.</p>
				{/* <h1 class="wow fadeInUp" data-wow-delay="1.6s">Web Design Conference</h1> */}
				{/* <a href="#overview" class="btn btn-lg btn-default smoothScroll wow fadeInUp hidden-xs" data-wow-delay="2.3s">LEARN MORE</a>
				<a href="#register" class="btn btn-lg btn-danger smoothScroll wow fadeInUp" data-wow-delay="2.3s">REGISTER NOW</a> */}
			</div>


		</div>
	</div>
</section>


<section id="about" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.6s">
				<h3>VISION</h3>
				<p>“We serve as a rainbow bridge” is our vision. We are serving as a bridge to achieve business engagement and to overcome difficulties, challenges between investors and host countries, especially Japan and Myanmar, and also local cooperators for mutual benefit in their business. Every individual is Novel. We are organized with persons of different ability, like colours of a rainbow, without discrimination of any kind based on race, colour, gender, language, religion, political or other opinion. Although our organization is composed of unique capabilities of human resources, moving towards the same direction with amazing team spirit.</p>
				{/* <p>Quisque facilisis scelerisque venenatis. Nam vulputate ultricies luctus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.</p> */}
			</div>

			<div class="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.6s">
				<h3>MISSION</h3>
				<p>"Growth TOGETHER” is our slogan. Our mission is to be getting sustainable success, together with our clients, alliance and each of our company members and employees by means of not only business profit but also their own development.</p>
			</div>


		</div>
	</div>
</section>


<section id="program" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.6s">
				<div class="section-title">
					<h2>Our Companies</h2>
					{/* <p>Quisque ut libero sapien. Integer tellus nisl, efficitur sed dolor at, vehicula finibus massa. Sed tincidunt metus sed eleifend suscipit.</p> */}
				</div>
			</div>

			<div class="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.9s">
				{/* <ul class="nav nav-tabs" role="tablist">
					<li class="active"><a href="#fday" aria-controls="fday" role="tab" data-toggle="tab">FIRST DAY</a></li>
					<li><a href="#sday" aria-controls="sday" role="tab" data-toggle="tab">SECOND DAY</a></li>
					<li><a href="#tday" aria-controls="tday" role="tab" data-toggle="tab">THIRD DAY</a></li>
				</ul> */}
				<div class="tab-content">

					<div role="tabpanel" class="tab-pane active" id="fday">
						<div class="col-md-2 col-sm-2 ">
							<img src="images/samadi-services.png" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							{/* <h6>
								<span><i class="fa fa-clock-o"></i> 09.00 AM</span> 
								<span><i class="fa fa-map-marker"></i> Room 240</span>
							</h6> */}
							<h3>SAMADI SERVICES Co., Ltd.</h3>
							<p>SAMDI Services is a company which provides BPO services to international companies in Myanmar. It was founded in 2007, and we have accomplished so much over the years. We are providing customer relation services as BPO to one of the Japanese car manufacturers which is market leader in Myanmar. One of our milestones is nationwide “SME Survey of Myanmar” jointly organized with JICA Myanmar in 2018.</p>
							<a href="#"><h4>Detail</h4></a>

						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/sheinllc.png" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							{/* <h6>
								<span><i class="fa fa-clock-o"></i> 10.00 AM</span> 
								<span><i class="fa fa-map-marker"></i> Room 360</span>
							</h6> */}
							<h3>SHEIN Planning & Consultant Co., Ltd.</h3>
							<p>Mauris at tincidunt felis, vitae aliquam magna. Sed aliquam fringilla vestibulum. Praesent ullamcorper mauris fermentum turpis scelerisque rutrum eget eget turpis.</p>
							<a href="#"><h4>Detail</h4></a>

						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/myanwave.png" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							{/* <h6>
								<span><i class="fa fa-clock-o"></i> 11.00 AM</span> 
								<span><i class="fa fa-map-marker"></i> Room 450</span>
							</h6> */}
							<h3>MYANWAVE Co., Ltd.</h3>
							<p>MYANWAVE is a leading private limited company in Myanmar, established in 2014, committed to enhancing the Human Resource Development Level of the country. Collaborating with its sister company, SHEIN Planning and Consultant LLC in Japan, MYANWAVE helps individuals and businesses across Myanmar achieve sustainable growth through knowledge and income enhancement. Be a part of this transformative journey towards a brighter future for Myanmar with MYANWAVE.</p>
							<a href="#"><h4>Detail</h4></a>

						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/skywalkermm.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							{/* <h6>
								<span><i class="fa fa-clock-o"></i> 11.00 AM</span> 
								<span><i class="fa fa-map-marker"></i> Room 450</span>
							</h6> */}
							<h3>SKY WALKER Co., Ltd.</h3>
							<p>SKYWALKER is a leading Myanmar-based tour company established in 2015, with a focus on organizing outbound tours to Japan. Our company specializes in providing customized concept and business tours tailored to meet the unique needs of our clients. With our extensive knowledge of Japanese culture and attention to detail, we strive to offer an unforgettable travel experience to our customers. Join us on an adventure and discover the beauty of Japan.</p>
							<a href="#"><h4>Detail</h4></a>

						</div>


					</div>

					{/* <div role="tabpanel" class="tab-pane" id="sday">
						<div class="col-md-2 col-sm-2">
							<img src="images/program-img4.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							<h6>
								<span><i class="fa fa-clock-o"></i> 11.00 AM</span> 
								<span><i class="fa fa-map-marker"></i> Room 240</span>
							</h6>
							<h3>Backend Development</h3>
							<h4>By Matt Lee</h4>
							<p>Integer rutrum viverra magna, nec ultrices risus rutrum nec. Pellentesque interdum vel nisi nec tincidunt. Quisque facilisis scelerisque venenatis. Nam vulputate ultricies luctus.</p>
						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/program-img5.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							<h6>
								<span><i class="fa fa-clock-o"></i> 01.00 PM</span> 
								<span><i class="fa fa-map-marker"></i> Room 450</span>
							</h6>
							<h3>Web Application Lite</h3>
							<h4>By David Orlando</h4>
							<p>Aliquam faucibus lobortis dolor, id pellentesque eros pretium in. Aenean in erat ut quam aliquet commodo. Vivamus aliquam pulvinar ipsum ut sollicitudin. Suspendisse quis sollicitudin mauris.</p>
						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/program-img6.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							<h6>
								<span><i class="fa fa-clock-o"></i> 03.00 PM</span> 
								<span><i class="fa fa-map-marker"></i> Room 650</span>
							</h6>
							<h3>Professional UX Design</h3>
							<h4>By James Lee Mark</h4>
							<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet. Dolore magna aliquam erat volutpat.</p>
						</div>
					</div> */}

					{/* <div role="tabpanel" class="tab-pane" id="tday">
						<div class="col-md-2 col-sm-2">
							<img src="images/program-img7.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							<h6>
								<span><i class="fa fa-clock-o"></i> 03.00 PM</span> 
								<span><i class="fa fa-map-marker"></i> Room 750</span>
							</h6>
							<h3>Online Shopping Business</h3>
							<h4>By Michael Walker</h4>
							<p>Aliquam faucibus lobortis dolor, id pellentesque eros pretium in. Aenean in erat ut quam aliquet commodo. Vivamus aliquam pulvinar ipsum ut sollicitudin. Suspendisse quis sollicitudin mauris.</p>
						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/program-img8.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							<h6>
								<span><i class="fa fa-clock-o"></i> 05.00 PM</span> 
								<span><i class="fa fa-map-marker"></i> Room 850</span>
							</h6>
							<h3>Introduction to Mobile App</h3>
							<h4>By Cherry Stella</h4>
							<p>Nunc eu nibh vel augue mollis tincidunt id efficitur tortor. Sed pulvinar est sit amet tellus iaculis hendrerit. Mauris justo erat, rhoncus in arcu at, scelerisque tempor erat.</p>
						</div>

						<div class="program-divider col-md-12 col-sm-12"></div>

						<div class="col-md-2 col-sm-2">
							<img src="images/program-img9.jpg" class="img-responsive" alt="program"/>
						</div>
						<div class="col-md-10 col-sm-10">
							<h6>
								<span><i class="fa fa-clock-o"></i> 07.00 PM</span> 
								<span><i class="fa fa-map-marker"></i> Room 750</span>
							</h6>
							<h3>Bootstrap UI Design</h3>
							<h4>By John David</h4>
							<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet. Dolore magna aliquam erat volutpat.</p>
						</div>
					</div> */}

				</div>
            </div>
		</div>
	</div>
</section>

<section id="program" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.6s">
				<div class="section-title">
					<h2>ORGANIZATION STURCTURE</h2>
					{/* <p>Quisque ut libero sapien. Integer tellus nisl, efficitur sed dolor at, vehicula finibus massa. Sed tincidunt metus sed eleifend suscipit.</p> */}
				</div>
			</div>

			<div class="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.9s">
				<div class="tab-content">

					<div role="tabpanel" class="tab-pane active" id="fday">

						<img src="images/Group-Organization-Chart.png" class="org-img"/>


					</div>


				</div>
            </div>
		</div>
	</div>
</section>

{/* <section id="register" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow fadeInUp col-md-7 col-sm-7" data-wow-delay="0.6s">
				<h2>Register Here</h2>
				<h3>Nunc eu nibh vel augue mollis tincidunt id efficitur tortor. Sed pulvinar est sit amet tellus iaculis hendrerit.</h3>
				<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet. Dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet consectetuer diam nonummy.</p>
			</div>

			<div class="wow fadeInUp col-md-5 col-sm-5" data-wow-delay="1s">
				<form action="#" method="post">
					<input name="firstname" type="text" class="form-control" id="firstname" placeholder="First Name"/>
					<input name="lastname" type="text" class="form-control" id="lastname" placeholder="Last Name"/>
					<input name="phone" type="telephone" class="form-control" id="phone" placeholder="Phone Number"/>
					<input name="email" type="email" class="form-control" id="email" placeholder="Email Address"/>
					<div class="col-md-offset-6 col-md-6 col-sm-offset-1 col-sm-10">
					<input name="submit" type="submit" class="form-control" id="submit" value="REGISTER"/>
					</div>
				</form>
			</div>

			<div class="col-md-1"></div>

		</div> 
	</div>
</section> */}


{/* <section id="faq" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow bounceIn col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 text-center">
				<div class="section-title">
					<h2>Do you have Questions?</h2>
					<p>Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet.</p>
				</div>
			</div>

			<div class="wow fadeInUp col-md-offset-1 col-md-10 col-sm-offset-1 col-sm-10" data-wow-delay="0.9s">
				<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

  					<div class="panel panel-default">
   						<div class="panel-heading" role="tab" id="headingOne">
      						<h4 class="panel-title">
        						<a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          							 What is Responsive Design?
        						</a>
      						</h4>
    					</div>
   						<div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
      						<div class="panel-body">
        						<p>Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet, wisi risus purus augue vulputate voluptate neque, curabitur dolor libero sodales vitae elit massa. Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet.</p>
								<p>Nunc eu nibh vel augue mollis tincidunt id efficitur tortor. Sed pulvinar est sit amet tellus iaculis hendrerit. Mauris justo erat, rhoncus in arcu at, scelerisque tempor erat.</p>
      						</div>
   						 </div>
 					</div>

    				<div class="panel panel-default">
   						<div class="panel-heading" role="tab" id="headingTwo">
      						<h4 class="panel-title">
        						<a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          							What are latest UX Developments?
        						</a>
      						</h4>
    					</div>
   						<div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
      						<div class="panel-body">
                            	<p>Nunc eu nibh vel augue mollis tincidunt id efficitur tortor. Sed pulvinar est sit amet tellus iaculis hendrerit. Mauris justo erat, rhoncus in arcu at, scelerisque tempor erat.</p>
        						<p>Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet, wisi risus purus augue vulputate voluptate neque, curabitur dolor libero sodales vitae elit massa. Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet.</p>
      						</div>
   						 </div>
 					</div>

 					<div class="panel panel-default">
   						<div class="panel-heading" role="tab" id="headingThree">
      						<h4 class="panel-title">
        						<a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          							What things about Social Media will be discussed?
        						</a>
      						</h4>
    					</div>
   						<div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
      						<div class="panel-body">
                            	<p>Aenean vulputate finibus justo et feugiat. Ut turpis lacus, dapibus quis justo id, porttitor tempor justo. Quisque ut libero sapien. Integer tellus nisl, efficitur sed dolor at, vehicula finibus massa.</p>
        						<p>Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet, wisi risus purus augue vulputate voluptate neque, curabitur dolor libero sodales vitae elit massa. Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet.</p>
      						</div>
   						 </div>
 					 </div>

 				 </div>
			</div>

		</div>
	</div>
</section> */}



{/* <section id="venue" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow fadeInLeft col-md-offset-1 col-md-5 col-sm-8" data-wow-delay="0.9s">
				<h2>Venue</h2>
				<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet. Dolore magna aliquam erat volutpat.</p>
				<h4>New Event</h4>
  				<h4>120 Market Street, Suite 110</h4>
  				<h4>San Francisco, CA 10110</h4>
				<h4>Tel: 010-020-0120</h4>		
			</div>

		</div>
	</div>
</section> */}


<section id="sponsors" class="parallax-section">
	<div class="container page-top">
		<div class="row">

			<div class="wow bounceIn col-md-12 col-sm-12">
				<div class="section-title">
					<h2>ACTIVITIES</h2>
					{/* <p>Lorem ipsum dolor sit amet, maecenas eget vestibulum justo imperdiet.</p> */}
				</div>
			</div>

            <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-1.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-1.jpg" class="zoom img-fluid "  alt=""/> 
					<span class="bottom-left">JICA Project For SME Development</span>                  
                </a>
            </div>


            <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-2.jpg"  class="fancybox" rel="ligthbox">
                    <img  src="images/img-2.jpg" class="zoom img-fluid"  alt=""/>
					<span class="bottom-left">Myanmar Industrial Development Program</span>                  

                </a>
            </div>
            
            <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-3.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-3.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">SME Development Seminar</span>                  


                </a>
            </div>
            
            <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-4.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-4.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">Industrial Development Program</span>                  

                </a>
            </div>
            
             <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-5.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-5.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">Myanmar SME Survey</span>                  

                </a>
            </div>
            
             <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-6.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-6.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">Business Matching</span>                  

                </a>
            </div>
            
             <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-7.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-7.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">HRD Program</span>                  

                </a>
            </div>
            
             <div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-8.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-8.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">Business Tour To Japan</span>                  

                </a>
            </div>

			<div class="col-lg-4 col-md-4 col-xs-6 thumb">
                <a href="images/img-9.jpg" class="fancybox" rel="ligthbox">
                    <img  src="images/img-9.jpg" class="zoom img-fluid "  alt=""/>
					<span class="bottom-left">Goverment Policy Dialogue</span>                  

                </a>
            </div>



		</div>
	</div>
</section>



<section id="contact" class="parallax-section">
	<div class="container">
		<div class="row">

			<div class="wow fadeInUp col-md-offset-1 col-md-5 col-sm-6" data-wow-delay="0.6s">
				<div class="contact_des">
					<h3>New Event</h3>
					<div style={{width: '100%'}}><iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sheinovic+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Calculate population in area</a></iframe></div>
				</div>
			</div>

			<div class="wow fadeInUp col-md-5 col-sm-6" data-wow-delay="0.9s">
				<div class="contact_detail">
					<div class="section-title">
						<h2>Keep in touch</h2>
					</div>
					<aside class="sidebar">
<div class="single contact-info">
<h3 class="side-title"></h3>
<ul class="list-unstyled">

<li>
<div class="icon"><i class="fa fa-clock-o"></i></div>
<div class="info"><p>Monday - Friday<br/>8 AM - 5 PM</p></div>
</li>

<li>
<div class="icon"><i class="fa fa-map-marker"></i></div>
<div class="info"><p>Room-203, 2nd Floor, Building(D),<br/>Yadanar Condominium, Yadanar Road, South Okkalapa Township Yangon, Myanmar</p></div>
</li>

<li>
<div class="icon"><i class="fa fa-phone"></i></div>
<div class="info"><p>+959 45004 2141</p></div>
</li>

<li>
<div class="icon"><i class="fa fa-envelope"></i></div>
<div class="info"><p>info@sheinovic.com</p></div>
</li>
</ul>
</div>
</aside>
				</div>
			</div>

		</div>
	</div>
</section>

        
      </div>
    )
  }
}

export default Home