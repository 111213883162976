import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <div>

        <footer>
            <div class="container">
                <div class="row">

                    <div class="col-md-12 col-sm-12">
                    <img  src="images/sheinovic.png" class="footer-logo"/>
                        <p class="wow fadeInUp" data-wow-delay="0.6s">Copyright &copy; 2024 All Rights Reserved.</p>

                        {/* <ul class="social-icon">
                            <li><a href="#" class="fa fa-facebook wow fadeInUp" data-wow-delay="1s"></a></li>
                            <li><a href="#" class="fa fa-twitter wow fadeInUp" data-wow-delay="1.3s"></a></li>
                            <li><a href="#" class="fa fa-dribbble wow fadeInUp" data-wow-delay="1.6s"></a></li>
                            <li><a href="#" class="fa fa-behance wow fadeInUp" data-wow-delay="1.9s"></a></li>
                            <li><a href="#" class="fa fa-google-plus wow fadeInUp" data-wow-delay="2s"></a></li>
                        </ul> */}

                    </div>
                    
                </div>
            </div>
        </footer>


        <a href="#back-top" class="go-top"><i class="fa fa-angle-up"></i></a>


      </div>
    )
  }
}

export default Footer