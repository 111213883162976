import React, { Component } from 'react'
import {BrowserRouter as Router,Routes,Route,Link} from "react-router-dom";
import Home from '../components/Home';


export class Header extends Component {
  render() {
    return (
    <Router>
      <div> 

        <div class="preloader">

            <div class="sk-rotating-plane"></div>

        </div>

        <div class="navbar navbar-fixed-top custom-navbar" role="navigation">
            <div class="container">

                <div class="navbar-header">
                    <button class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span class="icon icon-bar"></span>
                        <span class="icon icon-bar"></span>
                        <span class="icon icon-bar"></span>
                    </button>
                    <a href="#" class="navbar-brand"><img src="images/shn-logo.png" class="logo-brand"/></a>
                </div>

                <div class="collapse navbar-collapse">

                    <ul class="nav navbar-nav navbar-right">
                        <li><a href="#about" class="smoothScroll">About Us</a></li>
                        <li><a href="#companies" class="smoothScroll">Our Companies</a></li>
                        <li><a href="#structure" class="smoothScroll">Organization Structure</a></li>
                        <li><a href="#activities" class="smoothScroll">Activities</a></li>
                        <li><a href="#contact" class="smoothScroll">Contact Us</a></li>
                    </ul>

                </div>

            </div>
        </div>

        <Routes>
            <Route exact path="/" element={<Home/>} />
        </Routes>
        
      </div>
    </Router>  
    )
  }
}

export default Header